<template>
  <div>
    <p class="msg">
      <span class="left" @click="$router.go(-1)">返回</span>
      <span class="wan">在线预览</span>
    </p>
    <div class="identity">
      <p class="buddha">
        <span>头像</span>
        <!-- 女孩 -->
        <img
          class="icon"
          v-if="sex == '女'"
          src="../assets/userss.png"
          alt=""
        />
        <!-- 男孩 -->
        <img class="icon" v-if="sex == '男'" src="../assets/boy.png" alt="" />
      </p>
      <p class="buddha">
        <span>姓名</span>
        <span class="right">{{ username }}</span>
      </p>
      <p class="buddha">
        <span>性别</span>
        <span class="right">{{ sex }}</span>
      </p>
      <p class="buddha">
        <span>生日</span>
        <span class="right">{{ birthday }}</span>
      </p>
      <p class="buddha">
        <span>户籍地址</span>
        <span class="right">{{ census }}</span>
      </p>
      <p class="buddha">
        <span>手机号</span>
        <span class="right">{{ mobile }}</span>
      </p>
      <p class="buddha">
        <span>联系邮箱</span>
        <span class="right">{{ mailbox }}</span>
      </p>
      <p class="buddha">
        <span>期望薪资</span>
        <span class="right">{{ pay }}</span>
      </p>
      <p class="buddha">
        <span>意向行业</span>
        <span class="right">{{ industry }}</span>
      </p>
      <p class="buddha">
        <span>学历</span>
        <span class="right">{{ education }}</span>
      </p>
      <div class="schoolclass">
        <div @click="$router.push('/school')" id="buddha2" class="buddha">
          <span class="sr ss schollcla">教育院校及专业</span>
        </div>
        <div v-for="(item, index) in schoolobj" v-bind:key="index" class="s1">
          <div class="s2">
            <div>{{ item.school }}</div>
            <div>{{ item.start_time }} 至 {{ item.end_time }}</div>
          </div>
          <div class="s3">{{ item.education_background }}-{{ item.major }}</div>
        </div>
      </div>
      <p class="buddha">
        <span>政治面貌</span>
        <span class="right">{{ politics }}</span>
      </p>
      <p class="buddha">
        <span>工作年限</span>
        <span class="right">{{ time }}年</span>
      </p>
      <p class="buddha">
        <span>求职区域</span>
        <span class="right">{{ address }}</span>
      </p>
      <p>
        <span class="box2">过往履历/我的优势</span>
      </p>
            <div
        class="box"
        v-for="(item, index) in list"
        @click.stop="xg(item)"
        :key="index"
      >
        <div class="title">
          <span>{{ item.company_name }}</span>
        </div>
        <span class="position">工作职位：{{ item.position }}</span>
        <span class="item">{{ item.entry_time }}～{{ item.quit_time }}</span>
        <!-- <p class="p">
          {{ item.jobresume }}
        </p> -->
        <textarea rows="3" v-model="item.working_experience" disabled></textarea>
      </div>

      <p class="buddha">
        <span>职业资格证书/荣誉奖项</span>
      </p>
      <textarea
        name=""
        class="zg"
        id=""
        v-model="honor"
        cols="30"
        rows="10"
      ></textarea>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [],
      sex: "",
      username: "",
      pay: "",
      birthday: "",
      industry: "",
      job: "",
      education: "",
      politics: "",
      time: "",
      address: "",
      advantage: "",
      mailbox: "",
      mobile: "",
      major: "",
      census: "",
      honor: "",
      schoolobj: [],
    };
  },
  created() {
    let arr = JSON.parse(localStorage.getItem("list"));
    this.list = arr;
    console.error(this.list);
    let num = localStorage.getItem("intention");
    this.advantage = localStorage.getItem("advantage");
    // localStorage.getItem("");
    this.job = localStorage.getItem("station");
    this.sex = localStorage.getItem("sex"); //性别

    this.education = localStorage.getItem("education");
    this.politics = localStorage.getItem("face");
    this.address = localStorage.getItem("area");
    this.time = localStorage.getItem("agelimit");
    this.mailbox = localStorage.getItem("mailbox");
    this.username = localStorage.getItem("username");
    this.pay = localStorage.getItem("pay");
    this.birthday = localStorage.getItem("birthday");
    this.mobile = localStorage.getItem("mobile");
    this.industry = num;
    this.major = localStorage.getItem("major");
    this.census = localStorage.getItem("census");
    this.honor = localStorage.getItem("honor");
    this.schoolobj = JSON.parse(localStorage.getItem("schoolmsg"));
  },
};
</script>

<style scoped lang="less">
.schoolclass {
  border-bottom: solid 0.01rem #eee;
  padding: 0.63rem 0;
}
.identity .schoolclass .buddha {
  border-bottom: none;
}
.s1 {
  margin: 0 0.2rem;
}
.s2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.13rem;
  margin-top: 0.15rem;
}
.s2 > :first-child {
  font-size: 0.34rem;
  line-height: 0.48rem;
  color: #333333;
}
.s2 > :nth-child(2) {
  color: #333333;
  font-size: 0.2rem;
  line-height: 0.26rem;
}
.s3 {
  font-size: 0.24rem;
  line-height: 0.33rem;
  color: #333333;
}
.addclose {
  display: flex;
  align-items: center;
}
.addclose > :first-child {
  margin-right: 0.3rem;
}
.addclose > :nth-child(2) {
  background-color: #f55613;
  border: 1px solid #f55613;
}
.scclas {
  margin-bottom: 0.3rem;
}
.addschoolclass {
  display: flex;
  align-items: center;
  border: 1px solid #38f;
  width: 1.5rem;
  justify-content: center;
  background-color: #38f;
  color: #ffffff;
  border-radius: 0.1rem;
  margin-top: 0.3rem;
  padding: 0.05rem;
}
.smsg {
  display: flex;
  align-items: center;
}
.smsg input {
  border: none;
  width: 2.5rem;
}
.smsg > :nth-child(2) {
  border-bottom-color: #697386;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  font-size: 0.3rem;
  padding: 0.1rem 0;
  width: 2.5rem;
}
.schollcla {
  // min-width: 2.6rem;
}
.schoolclass {
  font-size: 0.3rem;
  line-height: 0.4rem;
}
.msg {
  height: 0.88rem;
  line-height: 0.88rem;
  line-height: 0.48rem;
  border-bottom: solid 0.01rem #eeeeee;
}
.ss::before {
  content: "*";
  color: #ff0000;
  font-size: 0.25rem;
  position: relative;
  margin-right: 0.1rem;
  /* top: 0.1rem; */
}
.msg .wan {
  margin-left: 2.5rem;
  font-size: 0.34rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #222222;
}
.identity {
  margin: 0rem 0.32rem;
}

.identity .buddha {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: solid 0.01rem #eee;
  font-size: 0.3rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #697386;
  line-height: 1.46rem;
}

.identity .buddha .lian {
  border: none;
}

.btn {
  width: 6.86rem;
  height: 0.96rem;
  background: linear-gradient(90deg, #f55613 0%, #fc9e46 100%);
  border-radius: 0.48rem;
  font-size: 0.36rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  text-align: center;
  line-height: 0.96rem;
  margin-top: 1.38rem;
  margin-left: 0.34rem;
  margin-bottom: 0.78rem;
}
#buddha2 > .schollcla {
  line-height: 0.4rem;
}
.msg {
  height: 0.88rem;
  line-height: 0.88rem;
  line-height: 0.48rem;
  border-bottom: solid 0.01rem #eeeeee;
}
.zg {
  height: 2rem;
  width: 100%;
  font-size: 0.3rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #697386;
}
.box2 {
  font-size: 0.3rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #697386;
}
.yc {
  transform: translateY(0.6rem);
  line-height: 0.3rem;
  // width: 100%;
  // overflow: hidden; //超出的文本隐藏
  // text-overflow: ellipsis; //溢出用省略号显示
  // white-space: nowrap;
}
.box {
  // position: relative;
  // height: 2.5rem;
  border-bottom: 1px solid #ccc;
  margin-bottom: 0.2rem;
  textarea {
    resize: none;
    // position: absolute;
    // top: 1rem;
    width: 100%;
    height: 1.6rem;
    font-size: 0.3rem;
    border: 0 none;
  }
  textarea:disabled {
    background-color: #fff;
  }
  .p {
    // position: absolute;
    // top: 0.8rem;
    font-size: 0.3rem;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    word-break: break-all;
    display: -webkit-box; /**对象作为伸缩盒子模型展示**/
    -webkit-box-orient: vertical; /**设置或检索伸缩盒子对象的子元素的排列方式**/
    -webkit-line-clamp: 3; /**显示的行数**/
    overflow: hidden; /**隐藏超出的内容**/
  }
  .item {
    // position: absolute;
    float: right;
    // right: 0;
    top: 0.4rem;
    font-size: 0.3rem;
    color: #ccc;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
  }
  .title {
    display: flex;
    justify-content: space-between;
    font-size: 0.34rem;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #222222;
    span {
      &:nth-child(2) {
        line-height: 0.5rem;
        font-size: 0.28rem;
        color: #ccc;
      }
    }
  }
  .position {
    margin-top: 0.1rem;
    margin-bottom: 0.1rem;
    // position: absolute;
    font-size: 0.2rem;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #222222;
  }
}
.msg span {
  font-size: 0.34rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #222222;
}

.msg .xia {
  float: right;
  margin-top: 0.24rem;
  margin-right: 0.3rem;
}

.msg .wan {
  margin-right: 0.3rem;
  margin-left: 2.5rem;
}
.left {
  float: left;
  line-height: 0.88rem;
  margin-left: 0.2rem;
}
.identity {
  margin: 0rem 0.32rem;
}
.identity .buddha {
  height: 1.46rem;
  border-bottom: solid 0.01rem #eee;
  font-size: 0.3rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #697386;
  line-height: 1.46rem;
}

.identity .buddha .icon {
  float: right;
  width: 1.08rem;
  height: 1.08rem;
  margin: 0.19rem 0rem;
}

.identity .buddha .lian {
  float: right;
  height: 0.74rem;
  margin-top: 0.36rem;
  border: none;
}

.identity .sexs {
  display: flex;
  height: 1.46rem;
  border-bottom: solid 0.01rem #eee;
  font-size: 0.3rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #697386;
  /* line-height: 1.46rem; */
}

.identity .se {
  margin-top: 0.52rem;
}
.right {
  float: right;
}
.box {
  font-size: 0.28rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #616b80;
}
textarea {
  width: 6.68rem;
  font-size: 0.28rem;
  border: 0 none;
  font-family: PingFangSC-Medium, PingFang SC;
}
textarea:disabled {
  background-color: #fff;
}
</style>
